import { SettingsInterface } from './settings.interface';
import { inject, Injectable } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable({ providedIn: 'root' })
export class SettingsService<T extends SettingsInterface> {
  cookieService = inject(SsrCookieService);

  update(settings: Partial<T>) {
    this.setCookie(settings);
  }

  setCookie(settings: Partial<T>): void {
    // Cookie expires after 365 days
    const cookieExpire = new Date();
    cookieExpire.setDate(cookieExpire.getDate() + 365);

    this.cookieService.set('settings', JSON.stringify(settings), { expires: cookieExpire });
  }

  getFromCookie(): T | undefined {
    return this.cookieService.get('settings') ? JSON.parse(this.cookieService.get('settings')) : undefined;
  }
}
